import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import roundLogo from '../../../styles/images/idelo_simple_logo.png'

import './Footer.css'


const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

      
    return (
        <footer>
            <section>
                <div className="company">
                    <h3>IDELO SARL</h3>
                    <div>
                        <p>SIRET 894 206 481 00026</p>
                        <p>06.92.87.13.93</p>
                        <p>idelo@vsite.fr</p>
                    </div>
                </div>
                <div className="sitemap">
                    <Link to="/">ACCUEIL</Link>
                    <Link to="/softwares">LOGICIELS</Link>
                    <Link to="/cash-registers">CAISSES</Link>
                    <Link to="/peripherals">PERIPHERIQUES</Link>
                    <button onClick={scrollToTop} className="back-to-top">&#8613;</button>
                </div>
            </section>
            <div className="copyright">
                <p>© Idelo 2022</p>
                <p className="footer-bar">|</p>
                <p>Tous droits réservés</p>
                <p className="footer-bar">|</p>               
                <img className="logo-round" src={roundLogo}/>
                <p className="footer-bar">|</p>
                <Link to="/under-construction">Mentions légales</Link>
                <p className="footer-bar">|</p>
                <Link to="/under-construction">CGV</Link>
            </div>
        </footer>
    );
};

export default Footer;