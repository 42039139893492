import React, { useLayoutEffect } from 'react';

import './Error.css'
import warning from '../../styles/images/warning.png'
import { useNavigate } from 'react-router-dom'

const Error = () => {

    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/')
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="error">
            <img style={{width: "15vw", marginBottom:"3%"}} src={warning}/>
            <h1>Cette page n'existe pas !</h1>
            <button
            className="back-home-btn"
            onClick={navigateToHome}
            >Retour à l'accueil</button>
        </div>
    );
};

export default Error;