import React from 'react';
import { HashLink } from 'react-router-hash-link';

import cashDrawer from '../../../styles/images/cash_drawer.png'

import '../../../styles/productCard.css'

const Drawers = () => {


    return (
        <HashLink smooth to="/peripherals#drawer">
            <div
            className="product-card">
                <h3>Nos tirroirs caisses → </h3>
                <div className="drawer-cards">
                    <img src={cashDrawer}/>
                </div>
            </div>
        </HashLink>
    );
};

export default Drawers;