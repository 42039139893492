import React from 'react';

import './AdminPage.css'
import LoginForm from '../../components/LoginForm/LoginForm'

const AdminPage = () => {
    return (
        <div className="admin">
            <LoginForm />
        </div>
    );
};

export default AdminPage;