export async function fetchSQL(url, setStateName, setLoading) {
    try {
        setLoading(true)
        const response = await fetch(url)
        .then((response) => response.json())
        console.log(response)
        setStateName(response)
    }
    catch(err) {
        console.log(err);
    }
    finally {
        setLoading(false);
    }
}

export async function updateProduct(data, url) {
    fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response.status)
        if (response.status == 200) {
          return response.json();
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .then((response) => {
        console.debug(response.message);
      })
      .catch((error) => {
        console.error(error);
      });
  }

export default async function deleteProduct(data, id) {

    const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

    fetch(isDev ? `http://localhost:8000/getperipherals/${id}` : `https://mapage.vsite.fr/getperipherals/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
    body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => console.log(res.status));
}

export async function createProduct(data, url) {
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response.status)
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .then((response) => {
        console.debug(response.message);
      })
      .catch((error) => {
        console.error(error);
      });
  }