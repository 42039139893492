import React from "react";


import "./SoftwareSlider";

import leftArrow from '../../styles/images/left-arrow.png';
import rightArrow from '../../styles/images/right-arrow.png';

export default function BtnSlider({ direction, moveSlide }) {
  return (
    <button
      onClick={moveSlide}
      className={direction === "next" ? "btn-slide next" : "btn-slide prev"}
    >
      <img src={direction === "next" ? rightArrow : leftArrow} />
    </button>
  );
}