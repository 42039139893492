import React, { useState } from 'react';
import deleteProduct from '../../utilities/fetchSQL'
import updateProduct from '../../utilities/fetchSQL'

import edit from '../../styles/images/editer.png'
import deletebtn from '../../styles/images/delete.png'
import done from '../../styles/images/coche.png'

function EditPopup ({ itemCount, obj}) {

    const [deleteProd, setDeleteProd] = useState()
    const [editProd, setEditProd] = useState()
    const [prod, setProd] = useState(obj)
    const [prodDeleted, setProdDeleted] = useState()
    
    function editProduct(){
        setEditProd(!editProd)
    }

    function updateState(){
        console.log(prod)
        setEditProd(!editProd)
    }

    return (
        <div style={{backgroundColor: editProd ? "#d3d5da" : "inherit"}} >
            <div style={{display: "flex", gap: "0.5vw"}}>
                    <p>{itemCount}</p>
                    <div 
                    style={{display: "flex"}} 
                    className="edit-listing">
                        <h4>{obj.product_name}</h4>
                        {!editProd ? (
                            <img style={{marginLeft: "1%"}} src={edit} onClick={()=> {editProduct()}}/>
                        ) : (
                            <img style={{marginLeft: "1%"}} src={done} onClick={()=> {updateState()}}/>
                        )}
                        {deleteProd ? (
                            <div className="pop-up">
                            <p>Supprimer ?</p>
                                <div className="buttons">
                                    <button
                                        style={{color: "green"}} 
                                        onClick={() => {
                                        deleteProduct({}, obj.id)
                                        setDeleteProd(false)
                                        setProdDeleted(true)}}>
                                            OUI</button>
                                    <button 
                                        style={{color: "red"}} 
                                        onClick={() => {
                                        setDeleteProd(false)}}>
                                            NON</button>
                                </div>
                                {prodDeleted && <p>La suppression sera bientôt effectuée.</p>}
                            </div> 
                        ) : (
                            <img style={{marginLeft: "1%"}} onClick={()=> {
                                editProd ? setEditProd(!editProd) :  setDeleteProd(true)
                            }} src={deletebtn}/>
                        )}
                        
                    </div>
            </div>
            <div style={{paddingLeft: "2%"}}>
                <p>{obj.product_brand}</p>
                <p>{obj.product_description}</p>
            </div>
        </div>
    );
};

export default EditPopup;