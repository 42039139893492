import React from 'react';
import { HashLink } from 'react-router-hash-link';

import printerOne from '../../../styles/images/printers/printer01.png'
import printerTwo from '../../../styles/images/printers/printer03.png'

const Printers = () => {


    return (
        <HashLink smooth to="/peripherals#printer">
            <div
            className="product-card">
                <h3>Nos imprimantes → </h3>
                <div className="printer-cards">
                    <img src={printerOne}/>
                    <img src={printerTwo}/>
                </div>
            </div>
        </HashLink>
    );
};

export default Printers;