import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    getIdToken, 
    onAuthStateChanged
} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCYWAz2LhUq6jtv8CaXMw1HtNSETPfeG-Q",
    authDomain: "idelo-974.firebaseapp.com",
    projectId: "idelo-974",
    storageBucket: "idelo-974.appspot.com",
    messagingSenderId: "1077579519797",
    appId: "1:1077579519797:web:192f99a11ef8c850ecde7c",
    measurementId: "G-VVN7MBZEFW"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const getToken = new Promise((resolve, reject) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const {
        currentUser
      } = auth;
      const token = await getIdToken(currentUser, true);

      resolve(token);
    }
  });
});


const logInWithEmailAndPassword = async (email, password, url) => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      getToken.then((token) => {
        localStorage.setItem("token", token);
        document.location.href = url
      })
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
};

const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Lien de réinitialisation du mot de passe envoyé !");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
};

const logout = () => {
    signOut(auth);
    localStorage.removeItem("token");
  };

  export {
    auth,
    logInWithEmailAndPassword,
    sendPasswordReset,
    logout,
  };