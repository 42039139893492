import React, { useEffect, useLayoutEffect } from 'react';

import './Contact.css'

const Contact = () => {

    const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        document.title = "Contact - Idelo";  
      }, []);

    return (
        <div className="contact">
            <div style={{textAlign: "center", fontSize: "1.5em", width: "45vw", marginBottom: "5%", lineHeight: "2em", color: "var(--primary-color)"}}>
                <h3>Une <span className="orange-text">question</span> ? Vous avez besoin d'un <span className="orange-text">devis personnalisé</span> ? <span className="send">Envoyez</span> nous un message et nous vous répondrons dans les plus brefs délais.</h3>
            </div>
            <div className="contact-form">
                <h1>DEMANDE DE CONTACT</h1>
                <form action="https://formsubmit.co/idelo@vsite.fr" method="POST" className="inputs">
                    <input type="hidden" name="_subject" value="Demande de contact" />
                    <input type="text" name="Nom"placeholder="VOTRE NOM" required/>
                    <input 
                    required
                    type="email"
                    name="Adresse mail"
                    placeholder="VOTRE ADRESSE MAIL"/>
                    <select defaultValue="TYPE DE DEMANDE" name="Type de demande">
                        <option disabled  hidden>TYPE DE DEMANDE</option>
                        <option>DEMANDE DE DEVIS</option>
                        <option>DEMANDE DE RENSEIGNEMENT</option>
                        <option>AUTRE</option>
                    </select>
                    <textarea required type="text" name="Message" rows="10" placeholder="VOTRE MESSAGE"/>
                    <input type="hidden" name="_next" value={ isDev ? "http://localhost:3000/" : "https://idelo.vsite.fr/"}/>
                <button type="submit" className="validate-form">ENVOYER</button>
                </form>
            </div>
        </div>
    );
};

export default Contact;