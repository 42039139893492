import React, { useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom'

import Separator from '../../components/Separator/Separator'

import "./About.css"
import soft01 from '../../styles/Logiciel_capture/Fab2.png'
import soft02 from '../../styles/Logiciel_capture/G2POS-R4.png'
import soft03 from '../../styles/Logiciel_capture/CHQ1.png'
import pcsoft from '../../styles/images/logo-pcsoft.png'
import windev from '../../styles/images/windev.png'
import webdev from '../../styles/images/webdev.png'
import windevMobile from '../../styles/images/windev-mobile.png'

const About = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        document.title = "A propos - Idelo";  
      }, []);

    return (
        <div className="about">
            <h1>Idelo, bien plus qu'une entreprise de développement.</h1>
            <div className="about-card">
                <p className="about-text"><span className="experience">Avec plus de 15 ans d’expertise dans le developpement de logiciels</span> (TPV, gestion diverse), Idelo® est votre partenaire idéal pour répondre aux besoins de votre entreprise.</p>
                <div style={{marginBottom: "5%"}} className="pcsoft">
                    <p>Nous développons pour vous tout type de logiciels sur mesures, fonctionnants sous systèmes windows, linux et android.</p>
                    <p>Des applications développées grâce à la plateforme de PCSoft© : 
                        <Link 
                        onClick={() => {
                            window.open('https://pcsoft.fr', '_blank', 'noopener,noreferrer')
                        }}
                        to="/">www.pcsoft.fr</Link>
                    </p>
                    <div className="pcsoft-logo">
                        <img src={pcsoft}/>
                        <div className="windev-suite">    
                            <img src={windev}/>
                            <img src={webdev}/>
                            <img src={windevMobile}/>
                        </div>
                    </div>
                </div>
                <Separator />
                <p style={{fontSize: "1.2em", fontWeight: "600", marginBottom: "4 %"}}> Nos logiciels sont utilisés dans de nombreux domaines tels que le commerce, l'industrie, la restauration ...</p>
                <div className="soft-example" style={{padding: "2% 0% 2% 0%", display: "flex", justifyContent: "space-around"}}>
                    <div className="soft-img">
                        <img src={soft01}/>
                        <p>SamProd - Fabrication </p>
                    </div>
                    <div className="soft-img">
                        <img src={soft02}/>
                        <p>G2POS - Restauration</p>
                    </div>
                    <div className="soft-img">
                        <img src={soft03}/>
                        <p>CHQ - Suivi de chèques</p>
                    </div>
                </div>
            </div>
            <Separator />
            <div style={{margin: "auto", justifyContent:"center"}} className="ask-quote">
                    <h2>Une question ?</h2>
                    <button className="contact-quote-btn"><Link to="/contact">Demander un devis</Link></button>
            </div>
        </div>
    );
};

export default About;