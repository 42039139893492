import React from 'react';
import { HashLink } from 'react-router-hash-link';

import scannerOne from '../../../styles/images/peripherals/scanner01.jpg'
import scannerTwo from '../../../styles/images/scanner01.png'

const Scanners = () => {



    return (
        <HashLink smooth to="/peripherals#scanner">
            <div
            className="product-card">
                <h3>Nos périphériques → </h3>
                <div className="scanner-cards">
                    <img src={scannerOne}/>
                    <img src={scannerTwo}/>
                </div>
            </div>
        </HashLink>
    );
};

export default Scanners;