import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword} from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";


import './LoginForm.css'

const LoginForm = () => {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    
    const navigate = useNavigate();


    useEffect(() => {
        if (localStorage.getItem("token")) {navigate("/dashboard")} 
    }, [ loading]);

    return (
        <div className="login">
            <div className="login-text" style={{textAlign: "center", fontSize: "1.5em", width: "45vw", marginBottom: "5%", lineHeight: "2em", color: "var(--primary-color)"}}>
                <h3>Connexion</h3>
            </div>
            <div className="login-container">
                <input
                id="mail"
                type="text"
                className="login-textBox"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Adresse mail"
                />
                <input
                id="passw"
                type="password"
                className="login-textBox"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Mot de passe"
                />
                <button
                id="loginBtn"
                className="login-btn"
                onClick={() => logInWithEmailAndPassword(email, password, "/dashboard")}
                >
                Connection
                </button>
                <div className="reset-pass">
                <Link to="/reset">Mot de passe oublié ?</Link>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;