import React, { useLayoutEffect } from 'react';

const ThankYou = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
        <div>
            
        </div>
    );
};

export default ThankYou;