import React, {useEffect, useLayoutEffect} from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


import Separator from '../../components/Separator/Separator'
import Printers from '../../components/ProductCards/Printers/Printers';
import Scanners from '../../components/ProductCards/Scanners/Scanners';
import Drawers from '../../components/ProductCards/Drawers/Drawers';

import payment from '../../styles/images/card_payment.jpg'
import WomanRegister from '../../styles/images/woman_cashregister.jpg'

import g2Pos01 from '../../styles/Logiciel_capture/G2POS-1.png'
import depot01 from '../../styles/Logiciel_capture/Depot1.png'
import iphone from '../../styles/images/iphone.png'
import ipad from '../../styles/images/ipad.png'
import allInOne from '../../styles/images/cash-registers/cash_register01.jpg'
import doubleScreen from '../../styles/images/cash-registers/cash_register02.jpg'




import './Home.css'

const Home = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


  useEffect(() => {
    document.title = "Accueil - Idelo";  
  }, []);

    return (
        <main className="home"> 
            <section className="photos-introduction">
                <div className="home-introduction-text">
                    <img src={payment}/>
                    <h2>Nos solutions points de vente, adaptées à vos besoins.</h2>
                </div>
                <div className="photo-quote">
                    <div className="ask-quote">
                        <h2>Une question ?</h2>
                        <button className="contact-quote-btn"><Link to="/contact">Demander un devis</Link></button>
                    </div>
                    <img style={{width:"28vw", borderRadius: "25px"}} src={WomanRegister}/>
                </div>
            </section>
            <Separator />
            <section className="software">
                <h2>Nos logiciels, pour vous.</h2>
                <div className="software-product">
                    <div className="software-text">
                        <p>G2POS, votre nouveau logiciel d'encaissement TPV.</p>
                        <HashLink smooth to="/softwares#g2pos">En savoir plus</HashLink>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <img src={g2Pos01}/>
                        <small style={{marginTop: "2%"}}>G2POS</small>
                    </div>
                </div>
                <div className="software-product">
                    <div className="software-text">
                        <p>DEPOT vous permettra de gérer vos stocks et votre entrepôt comme si vous y étiez !</p>
                        <HashLink smooth to="/softwares#depot">En savoir plus</HashLink>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <img src={depot01}/>
                        <small style={{marginTop: "2%"}}>DEPOT</small>
                    </div>
                </div>
                <h2>Partout</h2>
                <div className="ios-software">
                    <div className="devices">
                        <img style={{width:"7vw"}} src={iphone}/>
                        <img style={{width:"15vw"}} src={ipad}/>
                    </div>
                    <div className="software-text">
                        <p>Des logiciels sur mesure faciles à utiliser,  rapides et efficaces, conformes aux dernières réglementations.*</p>
                    </div>
                </div>
            </section>
            <Separator />
            <section className="products">
                <h2>Notre matériel, vos bénéfices.</h2>
                <div className="product left">
                    <img style={{width: "30vw"}} src={allInOne} />
                    <div className="product-text">
                        <h3>Terminal All In One 21"</h3>
                        <p>Processeur i5 - 8Go - 240Go SSD</p>
                        <Link to="/contact">Reserver</Link>
                    </div>
                </div>
                <div className="product right">
                    <div className="product-text">
                        <h3>Terminal double affichage 15"</h3>
                        <p>Processeur i5 - 4Go - 64Go SSD</p>
                        <Link to="/contact">Reserver</Link>
                    </div>
                    <img style={{width: "30vw"}} src={doubleScreen} />
                </div>
                <h2 className="our-peripheral">Nos périphériques</h2>
                <div className="cards">
                    <Printers />
                    <Scanners />  
                    <Drawers />                   
                </div>
            </section>
            <Separator/>
            <div style={{margin: "auto", justifyContent:"center"}} className="ask-quote">
                    <h2>Une question ?</h2>
                    <button className="contact-quote-btn"><Link to="/contact">Demander un devis</Link></button>
            </div>
        </main>
    );
};

export default Home;