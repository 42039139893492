import React, { useEffect, useState, useLayoutEffect} from 'react';
import { Link } from 'react-router-dom';

import './Peripherals.css'

import Separator from '../../components/Separator/Separator'
import Loader from '../../components/Loader/Loader'


const Peripherals = () => {

    const [printers, setPrinters] = useState();
    const [scanners, setScanners] = useState();
    const [drawers, setDrawers] = useState();
    const [accessories, setAccessories] = useState();
    const [Loading, setLoading] = useState(false);
    
    // fetchSQL("https://mapage.vsite.fr/getperipherals/printers", setPrinters, setLoading)


    async function fetchPrinters() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getprinters", )
            .then((response) => response.json())
            console.log(response)
            setPrinters(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchScanners() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getscanners", )
            .then((response) => response.json())
            console.log(response)
            setScanners(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchDrawers() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getdrawers", )
            .then((response) => response.json())
            console.log(response)
            setDrawers(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchAccessories() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getaccessories", )
            .then((response) => response.json())
            console.log(response)
            setAccessories(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        fetchPrinters()
        fetchScanners()
        fetchDrawers()
        fetchAccessories()
    }, []);

    useEffect(() => {
        document.title = "Périphériques - Idelo";  
      }, []);

    return (

        
        <div className="peripheral">
                <h1 style={{marginBottom: "8%"}}>Périphériques</h1>
            <section>
                <div>
                    <h2 id="printer">Nos imprimantes</h2>
                </div>
                {Loading || !printers ? (
                    <Loader />
                ) : (
                    <div className="peripheral-card printer">
                        {printers.map((obj) => {
                            return (
                                <div className="cashregist-card">
                                    <img
                                    key={obj.id}
                                    src={obj.product_url}/>
                                    <div className="card-text">    
                                        <h3>{obj.product_name}</h3>
                                        <div>
                                            <p>{obj.product_description.substring(1, obj.product_description.length -1).split('"')}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </section>
            <Separator />
            <section>
                <div id="scanner">
                    <h2>Nos scanners</h2>
                </div>
                {Loading || !scanners ? (
                    <Loader />
                ) : (
                    <div className="peripheral-card scanner">
                        {scanners.map((obj, index) => {
                            return (
                                <div className="scanner-product-card">
                                    <img
                                    key={obj.id}
                                    src={obj.product_url}/>
                                    <div className="card-text">    
                                        <h3>{obj.product_name}</h3>
                                        <div>
                                            <p>{obj.product_description.substring(1, obj.product_description.length -1).split('"')}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </section>
            <Separator />
            <section> 
                <div id="drawer">
                    <h2>Nos tirroir-caisses</h2>
                </div>
                {Loading || !drawers ? (
                    <Loader />
                ) : (
                    <div className="peripheral-card drawer">
                        {drawers.map((obj, index) => {
                            return (
                                <div className="drawer-card">
                                    <img
                                    key={obj.id}
                                    src={obj.product_url}/>
                                    <div className="card-text">    
                                        <h3>{obj.product_name}</h3>
                                        <div>
                                            <p>{obj.product_description.substring(1, obj.product_description.length -1).split('"')}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </section>
            <Separator />
            <section>
                <div>
                    <h2>Nos accessoires</h2>
                </div>
                {Loading || !accessories ? (
                    <Loader />
                ) : (
                    <div className="peripheral-card">
                        {accessories.map((obj, index) => {
                            return (
                                <div className="accessory-card other">
                                    <img
                                    key={obj.id}
                                    src={obj.product_url}/>
                                    <div className="card-text">    
                                        <h3>{obj.product_name}</h3>
                                        <div>
                                            <p>{obj.product_description.substring(1, obj.product_description.length -1).split('"')}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </section>
            <Separator />
            <div style={{margin: "auto", justifyContent:"center"}} className="ask-quote">
                    <h2>Une question ?</h2>
                    <button className="contact-quote-btn"><Link to="/contact">Demander un devis</Link></button>
            </div>
        </div>
    );
};

export default Peripherals;