import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import Loader from '../../components/Loader/Loader'
import EditPopup from '../Popups/EditPopup'

import "./Dashboard.css";
import { auth, logout } from "../../firebase";
import addbtn from '../../styles/images/add_btn.png'


function Dashboard() {

    const headers = {
        headers : {
            Authorization: `${localStorage.getItem("token")}`
        }
    }

    useEffect(() => {
        document.title = "Dashboard";  
      }, []);

    const [user, setUser] = useState()


    const [cashReg, setCashReg] = useState();
    const [Loading, setLoading] = useState(false);
    const [printers, setPrinters] = useState();
    const [scanners, setScanners] = useState();
    const [drawers, setDrawers] = useState();
    const [accessories, setAccessories] = useState();
    const [userAuth, loading, error] = useAuthState(auth);

    const navigate = useNavigate();

    async function fetchRegisters() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getproducts", headers)
            .then((response) => response.json())
            console.log(response)
            setCashReg(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchPrinters() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getprinters", headers)
            .then((response) => response.json())
            setPrinters(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchScanners() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getscanners", headers)
            .then((response) => response.json())
            console.log(response)
            setScanners(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchDrawers() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getdrawers", headers)
            .then((response) => response.json())
            console.log(response)
            setDrawers(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchAccessories() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getaccessories", headers )
            .then((response) => response.json())
            console.log(response)
            setAccessories(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchRegisters()
        fetchPrinters()
        fetchScanners()
        fetchDrawers()
        fetchAccessories()
      }, []);

  useEffect(() => {
    if (loading) return;
    if (!userAuth) return navigate("/");
  }, [userAuth, loading]);

  let itemCount = 1

  return (
    <div className="dashboard">
        <section>
            <div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                <h2 style={{fontSize:"2em"}}>Mes produits</h2>
                <img id="addProd" className="add-product" onClick={()=> {navigate("/dashboard/add")}}src={addbtn}/>
            </div>
            <div className="admin-products">
                <div className="products-cat">
                    <div className="stock-title" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <h3>Caisses</h3>
                    </div>
                    {Loading || !cashReg ? (
                    <Loader />
                        ) : (
                            <div className="admin-products-listing">
                                {cashReg.map((obj) => {
                                    return (
                                        <EditPopup
                                            obj = {obj}
                                            itemCount={itemCount ++}
                                            />
                                    )
                        })}
                    </div>
                        )}
                </div>
                <div className="products-cat">
                    <div className="stock-title" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <h3>Imprimantes</h3>
                    </div>
                    {Loading || !printers ? (
                    <Loader />
                        ) : (
                            <div className="admin-products-listing">
                                {printers.map((obj) => {
                                    return (
                                            <EditPopup
                                            obj = {obj}
                                            itemCount={itemCount ++}
                                            />
                                    )
                                })}
                            </div>
                        )}
                </div>
                <div className="products-cat">
                    <div className="stock-title" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <h3>Scanners</h3>
                    </div>
                    {Loading || !scanners ? (
                    <Loader />
                        ) : (
                            <div className="admin-products-listing">
                                {scanners.map((obj) => {
                                    return (
                                        <EditPopup
                                        obj = {obj}
                                        itemCount={itemCount ++}
                                        />
                                    )
                        })}
                    </div>
                        )}
                </div>
                <div className="products-cat">
                    <div className="stock-title" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <h3>Tirroirs</h3>
                    </div>
                    {Loading || !drawers ? (
                    <Loader />
                        ) : (
                            <div className="admin-products-listing">
                                {drawers.map((obj) => {
                                    return (
                                        <EditPopup
                                        obj = {obj}
                                        itemCount={itemCount ++}
                                        />
                                    )
                        })}
                    </div>
                        )}
                </div>
                <div className="products-cat">
                    <div className="stock-title" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <h3>Divers</h3>
                    </div>
                    {Loading || !accessories ? (
                    <Loader />
                        ) : (
                            <div className="admin-products-listing">
                                {accessories.map((obj) => {
                                    return (
                                        <EditPopup
                                        obj = {obj}
                                        itemCount={itemCount ++}
                                        />
                                    )
                        })}
                    </div>
                        )}
                </div>
            </div>
        </section>
        <div style={{width: "13vw", border: "1px solid lightgrey", padding: "3%", backgroundColor: "rgb(236, 236, 236)"}} className="dashboard-container">
            <p style={{marginBottom: "8%", fontWeight: "bold"}}>Connecté en tant que</p>
            <div>{userAuth?.email}</div>
            <p className="dashboard-btn" onClick={logout}>Se déconnecter</p>
        </div>
    </div>
  );
}
export default Dashboard;