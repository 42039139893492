import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';



//--------------------IMPORT PAGES--------------------//

import Home from '../Home/Home';
import Softwares from '../Softwares/Softwares'
import CashRegisters from '../CashRegisters/CashRegisters'
import Peripherals from '../Peripherals/Peripherals'
import Contact from '../Contact/Contact'
import About from '../About/About'
import ThankYou from '../Thank You/ThankYou'
import UnderConstruction from '../Under Construction/UnderConstruction'
import AdminPage from '../AdminPage/AdminPage'

//--------------------IMPORT COMPONENTS--------------------//

import Header from '../../components/main/Header/Header';
import Footer from '../../components/main/Footer/Footer';
import Error from '../Error/Error';
import Dashboard from '../../components/Dashboard/Dashboard'
import AddProduct from '../../components/AddProduct/AddProduct'


function App() {
  
  return (
    <div className="app">
      <BrowserRouter>
          <Header />
          <Routes>
            <Route path="*" element={<Error />}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/idelo-admin" element={<AdminPage />}></Route>
            <Route path="/softwares" element={<Softwares />}></Route>
            <Route path="/about" element ={<About />}></Route>
            <Route path="/cash-registers" element={<CashRegisters />}></Route>
            <Route path="/peripherals" element={<Peripherals />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/thankyou" element={<ThankYou />}></Route>
            <Route path="/under-construction" element={<UnderConstruction />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/dashboard/add" element={<AddProduct />}></Route>
          </Routes>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
