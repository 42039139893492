import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import './UnderConstruction.css'
import construction from '../../styles/images/work-in-progress.png'

const UnderConstruction = () => {

    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/')
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="under-construction">
            <img src={construction}/>
            <h1>Désolé, cette page est encore en travaux !</h1>
            <button
            className="back-home-btn"
            onClick={navigateToHome}
            >Retour à l'accueil</button>
        </div>
    );
};

export default UnderConstruction;