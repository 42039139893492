import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";


import { auth, logout } from "../../../firebase";
import logo from '../../../styles/images/idelo_logo.png'
import menuLogo from '../../../styles/images/menu-logo.png'
import mailLogo from '../../../styles/images/letter.png'

import {UserContext} from '../../../utilities/context';

import './Header.css'

const Header = () => {

    const [ sideMenu, setSideMenu ] = useState(false)
    const user = useContext(UserContext)
    const [userAuth, loading, error] = useAuthState(auth);

    let navigate = useNavigate();

    const showMenu = () => setSideMenu(!sideMenu)

    function goToHome() {
        navigate("/", { replace: true })
    }

    return (
        <header>
            <div className="logo-nav">
                <img onClick={goToHome} id="idelo-logo" src={logo}/>
                <nav>
                    <Link to="/">Accueil</Link>
                    <Link to="/softwares">Logiciels</Link>
                    <Link to="/cash-registers">Caisses</Link>
                    <Link to="/peripherals">Périphériques</Link>
                    <Link to="/about">A propos</Link>
                </nav>
            </div>
            { userAuth ? (
                <div style={{fontSize:"2em"}} className="contact-us">
                    <Link to="/dashboard">STOCK</Link>
                </div>
            ) : (

            <div className="contact-us">
                <Link to="/contact">Nous contacter</Link>
                <Link to="/contact"><img style={{width: "1.5vw"}} src={mailLogo}/></Link>
            </div>
            )}
            <img className="menu-logo" onClick={showMenu} src={menuLogo}/>
            <div className={ sideMenu ? 'nav-menu active' : 'nav-menu'}>
                    <div onClick={showMenu} className="menu-resp-div">
                        <p className="close-menu"></p>
                    </div>
                    <Link onClick={showMenu} to="/">ACCUEIL</Link>
                    <Link onClick={showMenu} to="/softwares">LOGICIELS</Link>
                    <Link onClick={showMenu} to="/cash-registers">CAISSES</Link>
                    <Link onClick={showMenu} to="/peripherals">PERIPHERIQUES</Link>
                    <Link onClick={showMenu} to="/about">A PROPOS</Link>
                   { 
                   userAuth ? (<Link onClick={showMenu} to="/dashboard">STOCK</Link>) : (<Link onClick={showMenu} to="/contact">NOUS CONTACTER</Link>)
                }
            </div>
        </header>
    );
};

export default Header;