import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';

import './CashRegisters.css'
import Separator from '../../components/Separator/Separator'
import Loader from '../../components/Loader/Loader'


const Registers = () => {

    const [cashReg, setCashReg] = useState();
    const [Loading, setLoading] = useState(false);

    async function fetchRegisters() {
        try {
            setLoading(true)
            const response = await fetch("https://mapage.vsite.fr/getproducts", )
            .then((response) => response.json())
            console.log(response)
            setCashReg(response)
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        document.title = "Caisses - Idelo";  
      }, []);

      useEffect(() => {
        fetchRegisters();  
      }, []);

    return (

        <div className="cash-register-page">
            <h1>Nos caisses enregistreuses</h1>
            <p className="cash-text">Pour utiliser au mieux nos logiciels, Idélo vous propose ses caisses adaptées à vos besoins, que vous ayez besoin d'un, deux écrans, d'une tablette, d'un TPV, nous avons ce qu'il vous faut.</p>
            <section>
                {Loading || !cashReg ? (
                    <Loader />
                ) : (
                    <div className="cash-regist-card">
                        {cashReg.map((obj) => {
                            return (
                                <div style={{marginTop: "5%"}} className="cashregist-card">
                                    <img
                                    key={obj.id}
                                    src={obj.product_url}/>
                                    <div className="card-text">    
                                        <h3>{obj.product_name}</h3>
                                        <div>
                                            <p>{obj.product_description.substring(2, obj.product_description.length -1).split('"')}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </section>
            <Separator/>
            <div style={{margin: "auto", justifyContent:"center"}} className="ask-quote">
                    <h2>Une question ?</h2>
                    <button className="contact-quote-btn"><Link to="/contact">Demander un devis</Link></button>
            </div>
        </div>
    );
};

export default Registers;