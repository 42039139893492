import React, {useState} from 'react';
import { createProduct } from '../../utilities/fetchSQL';
import { useNavigate } from "react-router-dom";

import './AddProduct.css'

const AddProduct = () => {

    const navigate = useNavigate();
    const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

   const [productName, setProductName] = useState()
   const [productType, setProductType] = useState()
   const [productBrand, setProductBrand] = useState()
   const [productUrl, setProductUrl] = useState()
   const [ProductDescription, setProductDescription] = useState()

   const [productAdded, setProductAdded] = useState()

   function addProduct() {
    const newProduct = {
        product_name:`${productName}`,
        product_type: `${productType}`,
        product_brand: `${productBrand}`,
        product_url: `${productUrl}`,
        product_description: [`${ProductDescription}`]
    }

    createProduct(newProduct, isDev ? 'http://localhost:8000/getperipherals' : 'https://mapage.vsite.fr/getperipherals' )
   }


    return (
        <div className="add-prod">
            <h1>Ajouter un produit</h1>
            <form>
                 <div className="radio-inputs" onChange={(e) => setProductType(e.target.value)}>
                    <div className="input-type">
                    <input type="radio" value="cash_register" name="gender" /> TPV
                    </div>
                    <div id="printer" className="input-type">
                    <input type="radio" value="printer" name="product-type" /> Imprimante
                    </div>
                    <div className="input-type">
                    <input type="radio" value="scanner" name="product-type" /> Scanner
                    </div>
                    <div className="input-type">
                    <input type="radio" value="drawer" name="product-type" /> Tirroir-caisse
                    </div>
                    <div className="input-type">
                    <input type="radio" value="accessories" name="product-type" /> Autre
                    </div>
                 </div>
                <input 
                  id="prodName"
                  required type="text" 
                  name="product_name" 
                  onChange={(e) => setProductName(e.target.value)}
                  placeholder="Nom du produit"/>
                  <input 
                  id="prodBrand"
                required type="text" 
                name="product_brand" 
                onChange={(e) => setProductBrand(e.target.value)}
                placeholder="Marque du produit"/>
                <input 
                id="prodUrl"
                required type="text" 
                name="product_url" 
                onChange={(e) => setProductUrl(e.target.value)}
                placeholder="URL du produit"/>
                <textarea 
                id="prodDesc"
                type="text" 
                name="product_description" 
                rows="10" 
                onChange={(e) => setProductDescription(e.target.value)}
                placeholder="Description du produit"/>

                {productAdded && <p id="addedMessg" className="product-added" >" {productName} " a bien été ajouté, il s'affichera dans quelques instants dans votre stock.</p>}
            </form>
            {!productAdded ? (

                <button 
                id="prodAdd"
                type="submit" onClick={()=> {
                    addProduct()
                    setProductAdded(true)
                }} className="add-product-btn">AJOUTER</button>
            ) : (
                <button onClick={()=> {
                    navigate("/dashboard")
                }} className="add-product-btn">RETOUR AU STOCK</button>
            )}
        </div>
    );
};

export default AddProduct;