import React, { useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';

import SoftwareSlider from '../../components/SoftwareSlider/SoftwareSlider'
import Separator from '../../components/Separator/Separator'

import g2posRes01 from '../../styles/Logiciel_capture/G2POS-R1.png'
import g2posRes03 from '../../styles/Logiciel_capture/G2POS-R3.png'
import g2posRes04 from '../../styles/Logiciel_capture/G2POS-R4.png'
import depot01 from '../../styles/Logiciel_capture/Depot1.png'
import depot02 from '../../styles/Logiciel_capture/Depot2.png'
import depot03 from '../../styles/Logiciel_capture/Depot3.png'
import samProd01 from '../../styles/Logiciel_capture/Fab1.png'
import cheque from '../../styles/Logiciel_capture/CHQ1.png'
import goffice from '../../styles/Logiciel_capture/Goffice0.png'
import goffice01 from '../../styles/Logiciel_capture/Goffice1.png'
import goffice02 from '../../styles/Logiciel_capture/Goffice2.png'
import goffice03 from '../../styles/Logiciel_capture/Goffice3.png'
import goffice04 from '../../styles/Logiciel_capture/Goffice4.png'
import goffice05 from '../../styles/Logiciel_capture/Goffice5.png'
import goffice06 from '../../styles/Logiciel_capture/Goffice6.png'
import depotTablet01 from '../../styles/Logiciel_capture/depot_tablet01.png'
import depotTablet02 from '../../styles/Logiciel_capture/depot_tablet02.png'



import './Softwares.css'

const Softwares = () => {

    useEffect(() => {
        document.title = "Logiciels - Idelo";  
      }, []);

      useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className ="softwares">
            <h1>Nos logiciels</h1>
            <section className="g2pos software-description">
                <h2 id="g2pos" style={{marginLeft:"5%", marginBottom:"5%"}}>G2POS</h2>
                <div className="g2pos-classic">
                    <SoftwareSlider />
                    <div className="g2pos-text">
                        <p style={{marginBottom: "3%"}}>G2POS est un logiciel facile à utiliser, pour votre usage au quotidien, conçu pour être rapide et efficace tout en étant conforme à la <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => {
                            window.open("https://www.impots.gouv.fr/professionnel/questions/quel-est-le-champ-dapplication-de-lobligation-de-detenir-un-logiciel-de#:~:text=Le%20dispositif%20pr%C3%A9voit%20l'obligation,inalt%C3%A9rabilit%C3%A9%2C%20de%20s%C3%A9curisation%2C%20de%20conservation', '_blank', 'noopener,noreferrer")
                        }}>loi fiscale de 2018 *</span>.</p>
                        <p>Avec G2POS, retrouvez la gestion de vos articles, vos devis clients, vos factures, votre inventaire, vos promotions, étiquettes directement depuis votre logiciel de caisse !</p>
                        <p>Consultez également votre chiffre d'affaire à distance grâce à l'application mobile.</p>
                    </div>
                </div>
                <div className="g2pos-rest">
                    <h4>G2POS - Restauration</h4>
                    <div className="restaurant-images">
                        <img src={g2posRes01}/>
                        <img src={g2posRes03}/>
                        <img src={g2posRes04}/>
                    </div>
                    <p style={{textAlign: 'center', padding: "0 12% 0 12%"}}>G2POS comprend aussi une partie restauration, pour pouvoir gérer simplement vos salles, vos commandes (et vos livraisons), vos encaissements. Le service en salle n'a jamais été aussi facile ! </p>
                </div>
            </section>
            <Separator />
                <h2 style={{marginLeft:"5%", marginBottom:"5%"}} id="depot" >DEPOT</h2>
            <section className="software-description depot">
                <div className="depot-soft">
                    <div className="depot-desktop">   
                        <img style={{width: "35vw", marginBottom: "5%"}} src={depot01}/>
                        <p>Avec notre logiciel de gestion d'entrepôt, gérez en temps réel vos stocks, vos commandes et vos fournisseurs.</p>
                        <p>Importez votre listing de marchandises et contrôlez la réception des marchandises directement depuis notre application.</p>
                    </div>
                    <div className="depot-phone">
                        <div className="phone-view">
                            <img src={depot02}/>
                            <img src={depot03}/>
                        </div>
                        <div className="depot-tablet">
                            <img src={depotTablet01}/>
                            <img src={depotTablet02}/>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', marginTop: "5%"}}>Grâce à l'appli mobile, mettez à jour vos stocks directement depuis votre smartphone et votre tablette !</p>
                </div>
            </section>
            <Separator />
            <section className="software-description other">
                <h3 style={{fontSize: "1.5em", color: "var(--primary-color"}}>Idelo, des logiciels faits par vous, pour vous.</h3>
                <p style={{width: "45vw", marginTop: "2%"}}>Nous développons des logiciels selon vos besoins, adaptés à vos demandes. Contactez nous pour un <span className="custom-quote"><Link to="/contact">devis personnalisé.</Link></span></p>
                <div className="other-soft">
                    <h2>SAMPROD</h2>
                    <div className="other-text">
                        <p>Logiciel de suivi de production pour les besoins d'un préparateur de produits apéritifs. Gestion des stocks, des fabrications, et d'autres options réalisées suite à la demande du client. </p>
                        <img style={{width: "30vw"}} src={samProd01}/>
                    </div>
                </div>
                <div className="other-soft">
                    <h2>SCHEQUIER</h2>
                    <div className="other-text">
                        <p>SChequier est un logiciel de suivi de chéquier. Retrouvez tous vos chèques émis et reçus, gardez une trace de vos transactions pour faciliter votre comptabilité !</p>
                        <img style={{width: "30vw"}} src={cheque}/>
                    </div>
                </div>
            </section>
            <Separator />
            <section className="goffice-suite">
                <h3 style={{color: "var(--primary-color)", fontSize: "1.8em", marginBottom: "8%", textAlign: "center"}}>SUITE GOFFICE</h3>
                <div style={{display: "flex",flexDirection: "column", alignItems:"center"}}>
                    <p>Nous vous proposons aussi notre suite de logiciels de bureautique: <span style={{fontWeight:"600"}}>Goffice</span></p>
                    <img style={{width: "25vw", marginTop: "5%"}} src={goffice}/>
                </div>
                <div>
                    <h4 style={{margin: "5% 0 3% 0"}}>Des logiciels complets qui répondront à tous vos besoins</h4>
                    <figure>
                        <div>
                            <img src={goffice01}/>
                            <figcaption>Goffice Diagramme</figcaption>
                        </div>
                        <div>
                            <img src={goffice03}/>
                            <figcaption>Goffice Texte</figcaption>
                        </div>
                        <div>
                            <img src={goffice02}/>
                            <figcaption>Goffice Table</figcaption>
                        </div>
                        <div>
                            <img src={goffice04}/>
                            <figcaption>Goffice Image</figcaption>
                        </div>
                        <div>
                            <img src={goffice05}/>
                            <figcaption>Goffice PDF</figcaption>
                        </div>
                        <div>
                            <img src={goffice06}/>
                            <figcaption>Goffice HTML</figcaption>
                        </div>
                    </figure>
                </div>
                <Separator />
            </section>
            <div style={{margin: "auto", justifyContent:"center"}} className="ask-quote">
                    <h2>Une question ?</h2>
                    <button className="contact-quote-btn"><Link to="/contact">Demander un devis</Link></button>
            </div>
        </div>
    );
};

export default Softwares;